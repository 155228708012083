





















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ModalCardAlert extends Vue {
  @Prop(String) text!: string
  @Prop(String) subText!: string

  created() {
    setTimeout(() => {
      this.$emit('closeModal')
    }, 4000)
  }
}
