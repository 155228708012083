



























import { useGetBillingUsage } from '@/hooks/billing'
import { Component, Vue } from 'vue-property-decorator'
import { computed, SetupContext } from '@vue/composition-api'
import { usageMapping } from '@/constants/subscription'

@Component({
  setup(_, { root }: SetupContext) {
    const isYoutube = computed(() => root.$store.getters.isYoutube)
    const { data: usageData, isLoading: isUsageLoading } = useGetBillingUsage({
      enabled: isYoutube,
    })
    return {
      usageData,
      isUsageLoading,
    }
  },
})
export default class BillingUsage extends Vue {
  usageData!: any
  isUsageLoading!: boolean

  usageTypeText(usageType: keyof typeof usageMapping): string {
    return usageMapping[usageType] || 'Unknown Usage'
  }
  maxAllowed(limit: number) {
    return limit == -1 ? 'No Limit' : limit
  }
}
