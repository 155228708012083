var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isSummaryLoading || _vm.isInvoicesLoading || _vm.isCardLoading
        ? _c("div", {
            staticClass: "bg-adori-medium-gray br3 skeleton custom-size",
          })
        : _c("div", [
            _c("div", { staticClass: "w-100 flex mb4" }, [
              _c("div", { staticClass: "w-50" }, [
                !_vm.isYoutubePro
                  ? _c("div", { staticClass: "pa4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex w-100 white f4 mb3 justify-between",
                        },
                        [
                          _c("div", [_vm._v("Current Cost")]),
                          _c("div", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.invoiceData.due
                                    ? (_vm.invoiceData.due / 100).toFixed(2)
                                    : "0.00"
                                )
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "adori-gray f6" }, [
                        _c(
                          "div",
                          { staticClass: "flex w-100 mt2 justify-between" },
                          [
                            _c("div", [_vm._v("Cycle")]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.billingStart) +
                                  " - " +
                                  _vm._s(_vm.billingEnd)
                              ),
                            ]),
                          ]
                        ),
                        _vm.invoiceData.due
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "flex w-100 mt2 justify-between",
                                },
                                [
                                  _c("div", [_vm._v("Next payment")]),
                                  _c("div", [_vm._v(_vm._s(_vm.paymentEnd))]),
                                ]
                              ),
                              _c("div", { staticClass: "mt3 f7" }, [
                                _vm._v(
                                  "* current cost does not include sales tax if applicable"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "pl4 pr4 pb4 pt2" }, [
                  _c("div", { staticClass: "white fw3 f5 mb3" }, [
                    _vm._v("Usage"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "bb b--adori-light-gray pb2" },
                    _vm._l(_vm.billingInvoiceItems, function (billing, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "flex adori-gray f6 w-100 mt3 mb3",
                        },
                        [
                          _c("div", { staticClass: "w-40" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.transformCategory(billing.category)
                                ) +
                                "\n              "
                            ),
                          ]),
                          _c("div", { staticClass: "flex justify-end w-40" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  billing.qty
                                    ? billing.qty +
                                        " " +
                                        (billing.category === "video_dalle"
                                          ? ""
                                          : "min")
                                    : "0 " +
                                        (billing.category === "video_dalle"
                                          ? ""
                                          : "min")
                                ) +
                                "\n                x\n                " +
                                _vm._s(
                                  billing.unit_price
                                    ? " $" +
                                        _vm.autoFixed(
                                          billing.unit_price / 100
                                        ) +
                                        "/" +
                                        (billing.category === "video_dalle"
                                          ? "image"
                                          : "min")
                                    : ""
                                ) +
                                "\n              "
                            ),
                          ]),
                          _c("div", { staticClass: "flex justify-end w-20" }, [
                            _vm._v(
                              "\n                $" +
                                _vm._s(
                                  billing.total
                                    ? (billing.total / 100).toFixed(2)
                                    : "0.00"
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "w-50 adori-gray ml-auto" }, [
                    _c(
                      "div",
                      { staticClass: "flex w-100 f6 justify-between mt2" },
                      [
                        _c("div", { staticClass: "w-60 flex justify-end" }, [
                          _vm._v("Total"),
                        ]),
                        _c("div", { staticClass: "w-40 flex justify-end" }, [
                          _vm._v(
                            "\n                $" +
                              _vm._s(
                                _vm.invoiceData.total
                                  ? (_vm.invoiceData.total / 100).toFixed(2)
                                  : "0.00"
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex w-100 f6 justify-between mt1" },
                      [
                        _c("div", { staticClass: "w-60 flex justify-end" }, [
                          _vm._v("Credits"),
                        ]),
                        _c("div", { staticClass: "w-40 flex justify-end" }, [
                          _vm._v(
                            "\n                - $" +
                              _vm._s(
                                _vm.invoiceData.total
                                  ? (
                                      _vm.invoiceData.total / 100 -
                                      _vm.invoiceData.due / 100
                                    ).toFixed(2)
                                  : "0.00"
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex w-100 f6 justify-between mt1" },
                      [
                        _c("div", { staticClass: "w-60 flex justify-end" }, [
                          _vm._v("Current Cost"),
                        ]),
                        _c("div", { staticClass: "w-40 flex justify-end" }, [
                          _vm._v(
                            "\n                $" +
                              _vm._s(
                                _vm.invoiceData.due
                                  ? (_vm.invoiceData.due / 100).toFixed(2)
                                  : "0.00"
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "pa4" }, [
                  _c(
                    "div",
                    { staticClass: "f4 white flex justify-between mb3" },
                    [
                      _c("div", [_vm._v("Remaining Credits")]),
                      _c("div", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.creditBalanceAvailable
                                ? _vm.creditBalanceAvailable
                                : "0.00"
                            )
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "w-100 f6 adori-gray mt2" }, [
                    _c(
                      "div",
                      { staticClass: "w-100 flex justify-between f6 mt2" },
                      [
                        _c("div", [_vm._v("General credits")]),
                        _c("div", [
                          _vm._v(
                            "\n                $" +
                              _vm._s(
                                _vm.invoiceData.unconditional_balance
                                  ? (
                                      _vm.invoiceData.unconditional_balance /
                                      100
                                    ).toFixed(2)
                                  : "0.00"
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  !_vm.isYoutubePro
                    ? _c("div", { staticClass: "w-100 f6 adori-gray mt2" }, [
                        _c(
                          "div",
                          { staticClass: "w-100 flex justify-between f6 mt2" },
                          [
                            _c("div", [_vm._v("Video creation credits")]),
                            _c("div", [
                              _vm._v(
                                "\n                $" +
                                  _vm._s(
                                    _vm.invoiceData.video_conversion_balance
                                      ? (
                                          _vm.invoiceData
                                            .video_conversion_balance / 100
                                        ).toFixed(2)
                                      : "0.00"
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "w-100 flex justify-between f6 mt2" },
                          [
                            _c("div", [
                              _vm._v("Transcription/suggested tag credits"),
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n                $" +
                                  _vm._s(
                                    _vm.invoiceData.transcription_balance
                                      ? (
                                          _vm.invoiceData
                                            .transcription_balance / 100
                                        ).toFixed(2)
                                      : "0.00"
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "w-50 ml3" }, [
                _vm.cardAdded && _vm.isYoutube
                  ? _c("div", { staticClass: "w-100 pa4" }, [
                      _c("div", { staticClass: "flex w-100" }, [
                        _c("div", { staticClass: "white mb3 f5 w-50 pt2" }, [
                          _vm._v("Payment Details"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mb3 flex justify-end f5 w-50" },
                          [
                            _c("BaseButtonRed", {
                              attrs: { text: "Edit", onClick: _vm.addCard },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "w-100 adori-gray" }, [
                        _c(
                          "div",
                          { staticClass: "flex w-100 f6 justify-between mt2" },
                          [
                            _c("div", [_vm._v("Card")]),
                            _c("div", [
                              _c("span", { staticClass: "gray" }, [
                                _vm._v("XXXX"),
                              ]),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.cardData.last4) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "flex f6 justify-between mt2" },
                          [
                            _c("div", [_vm._v("Expiration Date")]),
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.cardData.expiry) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "flex f6 justify-between mt2" },
                          [
                            _c("div", [_vm._v("Email")]),
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.cardData.billing_details.email) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "flex f6 justify-between mt2" },
                          [
                            _c("div", [_vm._v("Country")]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.cardData.billing_details.country)
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : !_vm.cardAdded && _vm.isYoutube
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "w-100 white mt4 flex flex-column items-center f2",
                      },
                      [
                        _vm.networkType === "YOUTUBE_METERED"
                          ? _c("div", [
                              _vm._v("Get extra $5! "),
                              _c("sup", { staticClass: "adori-gray" }, [
                                _vm._v("*"),
                              ]),
                            ])
                          : _vm._e(),
                        _c("img", {
                          staticClass: "ml2",
                          attrs: {
                            src: require("@/assets/icons/card.svg"),
                            alt: "",
                            width: "150",
                            height: "250",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "mt2" },
                          [
                            _c("BaseButtonRed", {
                              staticClass: "f4",
                              attrs: {
                                text: "Add Card Now",
                                onClick: _vm.addCard,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._m(0),
                _c("div", { staticClass: "pa4" }, [
                  _c("div", { staticClass: "f5 white flex justify-between" }, [
                    _c("div", [_vm._v("Lifetime Credits Earned")]),
                    _c("div", [
                      _vm._v(
                        "$" +
                          _vm._s(
                            _vm.lifetime_credits_given
                              ? _vm.lifetime_credits_given
                              : "0.00"
                          )
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "mt2 pa4" },
              [
                _c("div", { staticClass: "w-100 f4 white" }, [_vm._v("Plans")]),
                _vm._l(_vm.plans, function (plan, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "w-70 pb4 pt4 flex mt3 items-center",
                        class: plan.active
                          ? "background-red"
                          : "background-black",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "white w-50 flex justify-center" },
                          [
                            _vm._v(
                              "\n            YOUTUBE: " +
                                _vm._s(plan.planName) +
                                _vm._s(plan.active ? "*" : "") +
                                "\n            "
                            ),
                            plan.subText
                              ? _c(
                                  "span",
                                  { staticClass: "f6 light-white ml1" },
                                  [_vm._v(_vm._s(plan.subText))]
                                )
                              : _vm._e(),
                          ]
                        ),
                        plan.active
                          ? _c(
                              "div",
                              { staticClass: "w-50 white flex justify-center" },
                              [_vm._v("Current Plan")]
                            )
                          : _c(
                              "div",
                              { staticClass: "w-50 white flex justify-center" },
                              [
                                _c("BaseButtonRed", {
                                  attrs: {
                                    text: plan.btnText,
                                    onClick: _vm.handleContact,
                                  },
                                }),
                              ],
                              1
                            ),
                      ]
                    ),
                  ])
                }),
                _vm.networkType === "YOUTUBE_METERED" && !_vm.cardAdded
                  ? _c(
                      "div",
                      { staticClass: "information-div adori-gray w-70 f6 mt4" },
                      [
                        _vm._v(
                          "\n        * 50% of the credits will be allocated towards transcription and suggested visuals.\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
      _vm.modalAlertStatus
        ? _c("ModalCardAlert", {
            attrs: {
              text: "Card Added successfully",
              subText:
                _vm.networkType === "YOUTUBE_ENTERPRISE"
                  ? ""
                  : "Congratulations you received 5$ credit",
            },
            on: {
              closeModal: function ($event) {
                _vm.modalAlertStatus = !_vm.modalAlertStatus
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "light-gray mt4 pl4 f6" }, [
      _vm._v("\n          Don’t hesitate to\n          "),
      _c(
        "a",
        {
          staticClass: "white pointer",
          attrs: { href: "emailto:hello@adorilabs.com" },
        },
        [_vm._v("contact us")]
      ),
      _vm._v("\n          with any billing questions or needs.\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }