


















































































































































import { NETWORKROLES } from '@/constants/roles'
import { NetworkQueryKeys, useGetNetworkUsers } from '@/hooks/network'
import { computed, SetupContext } from '@vue/composition-api'
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import YoutubeSignIn from '../Youtube/YoutubeSignIn.vue'
import AdoriService from '@/services/adori'
import AuthService from '@/services/auth'
import { QueryClient, useQueryClient } from 'vue-query'
import { SUPPORT_STUDIO_MAIL, SUPPORT_VIDEO_MAIL } from '@/constants/env'
import Youtube from '@/mixins/Youtube'
import get from 'lodash.get'

@Component({
  components: {
    YoutubeSignIn,
  },
  setup(props: any, context: SetupContext) {
    const queryClient = useQueryClient()
    const isEnabled = computed(
      () =>
        context.root.$store.getters?.currentNetwork?.role?.name === NETWORKROLES.owner ||
        context.root.$permissions.isManageNetworkBillingAllowed()
    )

    const { data: networkUsersData, isLoading: isLoadingNetworkUsers } = useGetNetworkUsers(
      { enabled: isEnabled },
      (data: any) => context.root.$store.commit('setUsers', data)
    )
    return {
      networkUsersData,
      isLoadingNetworkUsers,
      queryClient,
    }
  },
})
export default class Network extends Mixins(Youtube) {
  @Prop(Boolean) isNetworkOwner!: boolean
  @Prop(Boolean) isYoutube!: boolean

  @Getter networkId!: any
  @Getter networkType!: any
  @Getter currentNetwork!: any

  @Action showConfirm!: any
  @Action closeModal!: any

  @Action showAddMember!: any

  networkUsersData!: any
  isLoadingNetworkUsers!: boolean

  queryClient!: QueryClient

  resetPasswordLoader = false
  resetSuccess = false

  get networkUsers() {
    return (
      this.networkUsersData?.data?.network_users.filter(
        (obj: any) => ![SUPPORT_STUDIO_MAIL, SUPPORT_VIDEO_MAIL].includes(obj.user.email)
      ) || []
    )
  }

  get youtubeSignInStatus() {
    return get(this.summaryData, 'youtube', false)
  }

  get networkRoles() {
    return NETWORKROLES
  }

  get userEmail() {
    return this.$store.getters.email || ''
  }

  get descriptions() {
    if (this.isYoutube) {
      return this.currentNetwork.role.ytDescription
    }
    return this.currentNetwork.role.description
  }

  async handleRemoveUser(member: any) {
    this.showConfirm({
      title: `Remove ${member.role.name} rights for this user?`,
      description: `You are about to remove ${member.role.name} rights from this user. To restore you will have to add this user again.`,
      yesButtonText: 'Remove user',
      noButtonText: 'Cancel',
      onConfirm: async () => {
        await AdoriService.deleteNetworkUser(this.networkId, member.user.id)
        this.queryClient.invalidateQueries([NetworkQueryKeys.NETWORKUSERS, this.networkId])
        this.closeModal()
      },
      onCancel: async () => {
        this.closeModal()
      },
    })
  }

  handleAddMember() {
    this.showAddMember()
  }

  handleResetPassword() {
    this.resetPasswordLoader = true
    AuthService.changePassword(this.userEmail, () => {
      this.resetPasswordLoader = false
      this.resetSuccess = true
    })
  }
}
