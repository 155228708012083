




































import { Component, Mixins, Prop } from 'vue-property-decorator'
import disabledLogo from '@/assets/publish/btn_google_signin_dark_disabled_web@2x.png'
import activeLogo from '@/assets/publish/google_signin.png'
import Youtube from '@/mixins/Youtube'
import get from 'lodash.get'

@Component
export default class YoutubeSignIn extends Mixins(Youtube) {
  @Prop() showId!: string

  get youtubeProfile() {
    return get(this.accountData, 'gProfile', null)
  }

  get youtubeSignInStatus() {
    return get(this.summaryData, 'youtube', false)
  }

  get disabledLogo() {
    return disabledLogo
  }
  get activeLogo() {
    return activeLogo
  }
}
