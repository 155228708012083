var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isNetworkOwner || _vm.$permissions.isManageNetworkBillingAllowed()
      ? _c("div", { staticClass: "pa4 bg-adori-light-gray br2" }, [
          _c("div", { staticClass: "white mb3 f4" }, [
            _vm._v("Authentication"),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "w-50 mr4" }, [
              _c("div", { staticClass: "f5 white" }, [_vm._v("Youtube Login")]),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap justify-start bg-adori-very-light-gray br2 pa4 mv3",
                },
                [
                  _c("YoutubeSignIn"),
                  _vm.youtubeSignInStatus
                    ? _c("div", { staticClass: "white mt4" }, [
                        _c("div", [
                          _vm._v(
                            "\n              Adori will use YouTube Api Services to upload videos and access the following information from your\n              YouTube account\n            "
                          ),
                        ]),
                        _vm._m(0),
                        _vm._m(1),
                      ])
                    : _c("div", { staticClass: "white mt4" }, [
                        _vm._v(
                          "\n            By using this destination, Adori and you agree to\n            "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "blue link",
                            attrs: {
                              href: "https://www.youtube.com/t/terms",
                              target: "_blank",
                            },
                          },
                          [_vm._v("YouTube's Terms of Service,")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "blue link",
                            attrs: {
                              href: "https://developers.google.com/youtube/terms/api-services-terms-of-service",
                              target: "_blank",
                            },
                          },
                          [_vm._v("YouTube Api Services Terms of Service")]
                        ),
                        _vm._v("\n            and\n            "),
                        _c(
                          "a",
                          {
                            staticClass: "blue link",
                            attrs: {
                              href: "http://www.google.com/policies/privacy",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Google's Privacy Policy")]
                        ),
                        _vm._v(".\n          "),
                      ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "w-40" }, [
              _c("div", { staticClass: "f5 white" }, [
                _vm._v("Adori Password Reset"),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap justify-start bg-adori-very-light-gray br2 pa4 mv3 word-wrap",
                },
                [
                  _c("div", { staticClass: "white w-100" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$store.getters.email) +
                        "\n          "
                    ),
                  ]),
                  _c("BaseButtonRed", {
                    staticClass: "mt2 mb2",
                    attrs: {
                      loading: _vm.resetPasswordLoader,
                      text: "Change password",
                      onClick: _vm.handleResetPassword,
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.resetSuccess,
                          expression: "resetSuccess",
                        },
                      ],
                      staticClass: "f6 green",
                    },
                    [_vm._v("Reset link successfully sent to your email")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _vm._m(2),
              !["YOUTUBE", "YOUTUBE_METERED", "YOUTUBE_PRO"].includes(
                _vm.networkType
              ) || _vm.$env.envConstDev() === _vm.$env.windowOrigin()
                ? _c("BaseButtonRed", {
                    staticClass: "mt3 mb4",
                    attrs: {
                      icon: "add",
                      text: "Add member",
                      onClick: _vm.handleAddMember,
                    },
                  })
                : _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("BaseButtonRed", {
                          staticClass: "mt3 mb4 gray",
                          attrs: {
                            icon: "add",
                            text: "Add member",
                            disabled: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex white f6 ml2 items-center" },
                      [
                        _vm._v(
                          "\n          Want to use Teams and Permissions? Upgrade your account\n          "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "white ml1",
                            attrs: {
                              href: "https://www.adorilabs.com/#contact",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Contact us")]
                        ),
                      ]
                    ),
                  ]),
            ],
            1
          ),
          _c("hr"),
          _c("div", { staticClass: "white mt3 mb4 f4" }, [
            _vm._v("\n      Members in your network\n      "),
            _c("span", { staticClass: "silver f6 ml3" }, [
              _vm._v(
                "( " +
                  _vm._s(_vm.networkUsers && _vm.networkUsers.length) +
                  " )"
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex w-100 flex-wrap" },
            [
              _vm.isLoadingNetworkUsers
                ? _c(
                    "div",
                    { staticClass: "flex flex-wrap vh-30" },
                    _vm._l(new Array(5), function (_, index) {
                      return _c("div", {
                        key: index,
                        staticClass:
                          "bg-adori-gray bb bw1 b--adori-gray br2 w-30 ma2 skeleton custom-size",
                      })
                    }),
                    0
                  )
                : _vm._l(_vm.networkUsers || [], function (member) {
                    return _c(
                      "div",
                      {
                        key: member.user.email,
                        staticClass: "flex flex-wrap justify-start w-30 br2",
                        staticStyle: { margin: "1rem" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-100 pa4 bg-adori-very-light-gray br2 word-wrap",
                          },
                          [
                            _c("p", { staticClass: "white fw5 f4 mb2" }, [
                              _vm._v(_vm._s(member.user.name)),
                            ]),
                            _c("p", { staticClass: "white f5 mb2" }, [
                              _vm._v(_vm._s(member.role.name)),
                            ]),
                            _c("p", { staticClass: "white mb4" }, [
                              _vm._v(_vm._s(member.user.email)),
                            ]),
                            member.role.name !== _vm.networkRoles.owner &&
                            member.user.email !== _vm.userEmail
                              ? _c("BaseButtonBorder", {
                                  attrs: {
                                    icon: "remove_circle",
                                    text: "Remove user",
                                    params: member,
                                    onClick: _vm.handleRemoveUser,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
            ],
            2
          ),
        ])
      : _c("div", { staticClass: "pa4 bg-adori-light-gray br2" }, [
          _c("div", { staticClass: "silver f3 mb2" }, [
            _vm._v("\n      As a "),
            _c("span", { staticClass: "fw5" }, [
              _vm._v(_vm._s(_vm.currentNetwork.role.name)),
            ]),
            _vm._v(" you are allowed to:\n    "),
          ]),
          _c(
            "div",
            { staticClass: "mb4" },
            _vm._l(_vm.descriptions, function (des) {
              return _c("ul", { key: des, staticClass: "silver" }, [
                _c("li", { staticClass: "f4 mb2" }, [_vm._v(_vm._s(des))]),
              ])
            }),
            0
          ),
          _vm._m(3),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("Name and email on account/channel(stored for display)"),
      ]),
      _c("li", [_vm._v("Profile picture (displayed on this page)")]),
      _c("li", [
        _vm._v(
          "IDs of videos creating through this destination (stored for generating statistics)"
        ),
      ]),
      _c("li", [
        _vm._v("Video analytics (will be displayed in Adori Insights)"),
      ]),
      _c("li", [
        _vm._v(
          "Please note: Adori will store Youtube data longer than 30 calendar days"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n              You may revoke access to your YouTube account at any time from this url:\n              "
      ),
      _c(
        "a",
        {
          staticClass: "blue link",
          attrs: {
            href: "https://security.google.com/settings/security/permissions",
            target: "_blank",
          },
        },
        [_vm._v("https://security.google.com/settings/security/permissions")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "white mb3 f4" }, [
      _vm._v("\n        Add member to your network"),
      _c("span", { staticClass: "silver f6 ml3" }, [
        _vm._v("Invite other users to your network"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "white f4" }, [
      _vm._v(
        "\n      You do not have the required permissions to change settings!\n      "
      ),
      _c("div", { staticClass: "mt2" }, [
        _vm._v("Please contact the network owner and request any changes."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }