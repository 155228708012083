var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-column justify-between items-center ph3 pv3 mb1 bg-adori-light-gray br2 light-gray ba b--adori-gray mr3 mb3 mw-230",
    },
    [
      _c("div", { staticClass: "flex justify-center dim" }, [
        _c(
          "div",
          { staticClass: "w-100", on: { click: _vm.handleShowClick } },
          [
            _c("div", { staticClass: "title items-top pa1" }, [
              _c("img", {
                staticClass: "w4 h4",
                attrs: { src: _vm.rssFeed.imageUrl, alt: "" },
              }),
              _c("div", { staticClass: "mt2 truncate w4" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.rssFeed.name) + "\n        "
                ),
              ]),
              _vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "flex justify-center items-center" },
                    [
                      _c("div", { staticClass: "mr3 silver" }, [
                        _vm._v("Loading..."),
                      ]),
                      _c("div", { staticClass: "loader loader-inline" }),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        ),
      ]),
      _vm.isYoutubeData
        ? _c(
            "div",
            [
              _c("BaseButtonBorder", {
                staticClass: "mr2",
                attrs: {
                  icon: _vm.automationIcon,
                  text: _vm.publishStatusText,
                  disabled: _vm.isAutomationAdded && !_vm.isAutomationsEnabled,
                  onClick: _vm.handleAddAutomation,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }