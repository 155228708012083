







































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import moment from 'moment'
import SettingsPodcastCard from '@/components/Podcast/SettingsPodcastCard.vue'
import { SetupContext } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'
import { useFetchRssFeed } from '@/hooks/rssFeeds'

import banner_podcast_image_svg from '@/assets/podcast_image_svg.svg'
import HomeBanner from '@/components/Home/HomeBanner.vue'
import OverviewBanner from '@/components/Overview/OverviewBanner.vue'
import OverviewInfoCard from '@/components/Overview/EmptyState/OverviewInfoCard.vue'
import ShowSetting from '@/components/settings/ShowSetting.vue'

@Component({
  components: {
    HomeBanner,
    OverviewBanner,
    OverviewInfoCard,
    SettingsPodcastCard,
    ShowSetting,
  },
  setup(props: any, context: SetupContext) {
    const queryClient = useQueryClient()

    const { data: rssFeedsData, isFetching: rssFeedLoading } = useFetchRssFeed({}, props.isYoutube, (data: any) => {
      context.root.$store.commit('setRssFeeds', data.data)
      context.root.$store.commit('setRssFeedsCount', data.count)
      context.root.$store.commit('setRssFeedsOffset', data.count)
    })
    return {
      rssFeedsData,
      rssFeedLoading,
      queryClient,
    }
  },
})
export default class Show extends Vue {
  @Prop(Boolean) isNetworkOwner!: boolean
  @Prop(Boolean) isYoutube!: boolean

  @Getter searchedRssFeedsLazyLoader!: any
  @Getter rssFeedsLazyLoader!: any
  @Getter rssFeeds!: any

  bannerHeader: string = 'Create a new podcast'
  bannerSubHeader: string =
    'Manage and deliver unlimited podcast shows and episodes. <i>Adorify</i> your episodes with interactive tags to augment your story, and measure the jump in listener engagement and monetization opportunities!'
  bannerImageUrl: string = banner_podcast_image_svg

  podcastSearchValue = ''
  podcastSortOrder = 'latestCreated'

  rssFeedsData!: any
  rssFeedLoading!: any
  queryClient!: QueryClient

  selectedPodcast: any = null

  clearSearch() {
    this.podcastSearchValue = ''
  }

  setSortOrder(sortOrder: string) {
    this.podcastSortOrder = sortOrder
  }

  get filteredRssFeed() {
    const list = this.rssFeedsData.data
    let filteredList = []
    switch (this.podcastSortOrder) {
      case 'latestCreated':
        filteredList = list.sort((a: any, b: any) => moment(a.createdOn).unix() - moment(b.createdOn).unix()).reverse()
        break
      case 'AZ':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1))
        break
      case 'ZA':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1))
        break
      default:
        filteredList = list
    }
    if (this.podcastSearchValue) {
      return filteredList.filter((feed: any) => feed.name.toLowerCase().includes(this.podcastSearchValue.toLowerCase()))
    }
    return filteredList
  }

  handleShowClick(feed: any) {
    this.selectedPodcast = feed
  }
}
