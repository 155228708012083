var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "" },
      on: {
        closeModal: function () {
          _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [_c("p")]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex flex-column items-center pb5" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/done.gif"),
              alt: "animation",
              width: "200",
              height: "200",
            },
          }),
          _c("div", { staticClass: "white f3" }, [_vm._v(_vm._s(_vm.text))]),
          _c("div", { staticClass: "white mt3" }, [
            _vm._v(_vm._s(_vm.subText)),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }