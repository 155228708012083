



















































































































































































































import moment from 'moment'
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import isEmpty from 'lodash.isempty'
import get from 'lodash.get'
import ModalCardAlert from '../Modals/ModalCardAlert.vue'
import Billing from '@/mixins/Billing'

enum categoryConversion {
  video_conversion = 'Youtube Video Conversion',
  transcription = 'Youtube Transcription',
  video_ai = 'AI Usage',
  video_dalle = 'Dall-E Usage',
}

@Component({
  components: { ModalCardAlert },
})
export default class YoutubeBilling extends Mixins(Billing) {
  @Getter networkType!: any

  modalAlertStatus = false

  @Watch('cardData')
  handleCardData() {
    if (get(this.$router.currentRoute.query, 'card_added', false) && !isEmpty(this.cardData)) {
      this.modalAlertStatus = true
    }
  }

  get plans() {
    const plans = [
      {
        planName: 'STARTUP',
        subText: '(pay as you go)',
        btnText: 'Contact Us',
        active: this.networkType === 'YOUTUBE_METERED',
      },
      { planName: 'PARTNER PROGRAM', btnText: 'Coming Soon', active: this.isYoutubePro },
      {
        planName: 'ENTERPRISE',
        btnText: 'Contact Us',
        active: this.networkType === 'YOUTUBE_ENTERPRISE',
      },
    ]
    return plans
  }

  get isYoutube() {
    return ['YOUTUBE_METERED', 'YOUTUBE_ENTERPRISE'].includes(this.networkType)
  }

  get isYoutubePro() {
    return ['YOUTUBE_PRO'].includes(this.networkType)
  }

  get billingStart() {
    return moment(this.summaryData.current_period_start).format('ll')
  }
  get billingEnd() {
    return moment(this.summaryData.current_period_end).format('ll')
  }
  get paymentEnd() {
    return moment(this.summaryData.current_period_end).format('ll')
  }

  transformCategory(category_name: string) {
    // @ts-ignore
    return ['YOUTUBE_PRO'].includes(this.networkType) && category_name === 'video_conversion'
      ? `${categoryConversion[category_name]} (Transcription Included)`
      : // @ts-ignore
        categoryConversion[category_name]
  }
  autoFixed(amount: number) {
    // Start with a high number to be safe
    const result = amount.toFixed(5)
    let end = result.length
    // Keep looking for 0s and keep track
    while (end > 0 && result[end - 1] === '0') {
      --end
    }
    // Return without the zeroes
    return result.substring(0, end)
  }

  toFixed(num: any, fixed: number) {
    fixed = fixed || 0
    let front = Math.floor(num)
    let back = 0
    for (let i = 1; i <= fixed; i++) {
      let value = Math.floor(num * Math.pow(10, i)) % 10
      back += value / Math.pow(10, i)
    }
    return front + back
  }

  handleContact() {
    window.open('https://www.adorilabs.com/contact', '_blank')
  }
}
