


























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import YoutubeSignIn from '@/components/Youtube/YoutubeSignIn.vue'
import { computed, SetupContext } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'
import { NetworkQueryKeys, useGetShowUsers } from '@/hooks/network'
import { NETWORKROLES } from '@/constants/roles'
import AdoriService from '@/services/adori'
import get from 'lodash.get'

@Component({
  components: {
    YoutubeSignIn,
  },
  setup(props: any, context: SetupContext) {
    const queryClient = useQueryClient()

    const selectedPodcastUid = computed(() => props.podcast?.uid)

    const isEnabled = computed(() => context.root.$permissions.isShowMangeAllowed(selectedPodcastUid.value))

    const { data: showUsersData, isLoading: isLoadingShowUsers } = useGetShowUsers(
      props.isYoutube,
      selectedPodcastUid,
      { enabled: isEnabled },
      (data: any) => context.root.$store.commit('setShowUsers', { feedUid: selectedPodcastUid, showUsers: data || {} })
    )
    return {
      showUsersData,
      isLoadingShowUsers,
      queryClient,
    }
  },
})
export default class ShowSetting extends Vue {
  @Prop(Boolean) isYoutube!: boolean
  @Prop(Boolean) isNetworkOwner!: boolean
  @Prop() podcast!: any

  @Getter networkId!: any
  @Getter networkType!: any
  @Getter showUsers!: any
  @Getter currentNetwork!: any

  @Action showAddShowMember!: any
  @Action showConfirm!: any
  @Action closeModal!: any

  showUsersData!: any
  isLoadingShowUsers!: boolean
  queryClient!: QueryClient

  get rssShowUsers() {
    return this.showUsersData?.data?.show_users
  }

  get networkRoles() {
    return NETWORKROLES
  }

  get currentShowRole() {
    const feed = get(this.currentNetwork, 'feeds', []).filter(
      (obj: any) => ((obj.feed && obj.feed.uid) || (obj.ytFeed && obj.ytFeed.uid)) === this.podcast?.uid
    )[0]
    return feed
  }

  get userEmail() {
    return this.$store.getters.email || ''
  }

  handleAddShowMember() {
    this.showAddShowMember(this.podcast?.uid)
  }

  async handleRemoveShowUser(member: any) {
    this.showConfirm({
      title: `Remove ${member.role.name} rights for this user?`,
      description: `You are about to remove ${member.role.name} rights from this user. To restore you will have to add this user again.`,
      yesButtonText: 'Remove user',
      noButtonText: 'Cancel',
      onConfirm: async () => {
        this.isYoutube
          ? await AdoriService.deleteShowYoutubeUser(this.networkId, member.user.id, this.podcast?.uid)
          : await AdoriService.deleteShowUser(this.networkId, member.user.id, this.podcast?.uid)
        this.queryClient.invalidateQueries([NetworkQueryKeys.SHOWUSERS, this.networkId, this.podcast?.uid])
        this.closeModal()
      },
      onCancel: async () => {
        this.closeModal()
      },
    })
  }
}
