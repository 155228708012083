import { render, staticRenderFns } from "./YoutubeSignIn.vue?vue&type=template&id=403a1a39&scoped=true&"
import script from "./YoutubeSignIn.vue?vue&type=script&lang=ts&"
export * from "./YoutubeSignIn.vue?vue&type=script&lang=ts&"
import style0 from "./YoutubeSignIn.vue?vue&type=style&index=0&id=403a1a39&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "403a1a39",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('403a1a39')) {
      api.createRecord('403a1a39', component.options)
    } else {
      api.reload('403a1a39', component.options)
    }
    module.hot.accept("./YoutubeSignIn.vue?vue&type=template&id=403a1a39&scoped=true&", function () {
      api.rerender('403a1a39', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Youtube/YoutubeSignIn.vue"
export default component.exports