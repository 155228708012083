




















































import { useGetBillingCard } from '@/hooks/billing'
import Billing from '@/mixins/Billing'
import { computed, SetupContext } from '@vue/composition-api'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import ModalCardAlert from '../Modals/ModalCardAlert.vue'
import { Getter } from 'vuex-class'

@Component({
  components: { ModalCardAlert },
  setup(_, { root }: SetupContext) {
    const isYoutube = computed(() => root.$store.getters.isYoutube)

    const { data: cardData, isLoading: isCardLoading } = useGetBillingCard({
      enabled: isYoutube,
    })

    return {
      cardData,
      isCardLoading,
    }
  },
})
export default class Subscription extends Mixins(Billing) {
  @Getter isYoutubeNew!: boolean

  cardData!: any
  isCardLoading!: boolean

  modalAlertStatus = false

  @Watch('cardData')
  handleCardData() {
    if (get(this.$router.currentRoute.query, 'card_added', false) && !isEmpty(this.cardData)) {
      this.modalAlertStatus = true
    }
  }
}
