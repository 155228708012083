var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white" }, [
    _c("p", { staticClass: "f4" }, [_vm._v("Usage")]),
    _c("p", { staticClass: "f5 silver" }, [
      _vm._v("We believe in maintaining full transparency with our users."),
    ]),
    _c("div", { staticClass: "usage-table-container" }, [
      _c("table", { staticClass: "usage-table" }, [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(_vm.usageData.data, function (item, key) {
            return _vm.usageTypeText(item.usageType) !== "Unknown Usage"
              ? _c("tr", { key: key }, [
                  _c("td", [_vm._v(_vm._s(_vm.usageTypeText(item.usageType)))]),
                  _c("td", [_vm._v(_vm._s(_vm.maxAllowed(item.maxAllowed)))]),
                  _c("td", [_vm._v(_vm._s(item.used))]),
                ])
              : _vm._e()
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name of Usage")]),
        _c("th", [_vm._v("Maximum allowed limit")]),
        _c("th", [_vm._v("Used")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }