var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    !_vm.youtubeSignInStatus
      ? _c(
          "button",
          {
            staticClass: "pointer no-btn",
            on: {
              click: function ($event) {
                _vm.isAccountLoading || _vm.isSummaryLoading
                  ? ""
                  : _vm.youtubeSignInStatus
                  ? _vm.signOut()
                  : _vm.signIn()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "flex items-center",
                class: { "bg-white-50": _vm.isNotALlowedLogin },
              },
              [
                _c("img", {
                  attrs: {
                    height: "46",
                    src: _vm.isNotALlowedLogin
                      ? _vm.disabledLogo
                      : _vm.activeLogo,
                    alt: "googl-logo",
                  },
                }),
                _vm.isAccountLoading || _vm.isSummaryLoading
                  ? _c("div", { staticClass: "loader loader-inline ml2" })
                  : _vm._e(),
                _vm.isNotALlowedLogin
                  ? _c(
                      "i",
                      { staticClass: "material-icons gray f4 v-mid pa1" },
                      [_vm._v("lock")]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.youtubeSignInStatus
      ? _c(
          "button",
          {
            staticClass: "flex white google-btn items-center",
            class:
              _vm.isAccountLoading || _vm.isSummaryLoading
                ? "not-allowed"
                : "pointer",
            attrs: { disabled: _vm.isAccountLoading || _vm.isSummaryLoading },
            on: {
              click: function ($event) {
                _vm.youtubeSignInStatus ? _vm.signOut() : _vm.signIn()
              },
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/publish/google.png"),
                height: "20",
                width: "20",
                alt: "googl-logo",
              },
            }),
            _c("div", { staticClass: "flex flex-column ml1" }, [
              _vm.isAccountLoading || _vm.isSummaryLoading
                ? _c("span", { staticClass: "loader loader-inline ml3 mr3" })
                : _c("span", [
                    _vm._v(
                      _vm._s(_vm.youtubeSignInStatus ? "Sign Out" : "Sign In")
                    ),
                  ]),
            ]),
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "white mt2 w-100" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.youtubeSignInStatus ? _vm.youtubeProfile.name : "") +
          "\n  "
      ),
    ]),
    _c("div", { staticClass: "white w-100" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.youtubeSignInStatus ? _vm.youtubeProfile.email : "") +
          "\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }