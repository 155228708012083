var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c("TheSideNavigation"),
      _c(
        "div",
        { staticClass: "limiter scrollbar mb5" },
        [
          _c("TheTitle", {
            attrs: {
              backIcon: "",
              route: _vm.route,
              icon: "settings_applications",
              title: "Account Settings",
              large: true,
            },
          }),
          _c("div", { staticClass: "ph3 shadow-2 mb3" }, [
            !_vm.selectedRssFeedUid
              ? _c(
                  "div",
                  _vm._l(_vm.tabList, function (tab) {
                    return _c(
                      "a",
                      {
                        staticClass: "fw3 f5 dib link glow pv3 pointer mr4",
                        class:
                          _vm.selectedTab === tab.value
                            ? "light-gray bb b--white bw1"
                            : "silver",
                        on: {
                          click: function () {
                            return _vm.selectTab(tab.param)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(tab.title) + "\n        "
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c("div", [
                  _c(
                    "i",
                    {
                      staticClass:
                        "material-icons light-gray dim mb2 pointer f2 mt2",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return function () {
                            return (_vm.selectedRssFeedUid = "")
                          }.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("keyboard_backspace")]
                  ),
                ]),
          ]),
          _vm.selectedTab === "NETWORK"
            ? _c("Network", {
                attrs: {
                  isNetworkOwner: _vm.isNetworkOwner,
                  isYoutube: _vm.isYoutubeNew || _vm.isYoutubeOld,
                },
              })
            : _vm._e(),
          _vm.selectedTab === "SHOW"
            ? _c("Shows", {
                attrs: {
                  isNetworkOwner: _vm.isNetworkOwner,
                  isYoutube: _vm.isYoutubeNew || _vm.isYoutubeOld,
                },
              })
            : _vm._e(),
          _vm.selectedTab === "BILLING" &&
          _vm.isYoutubeNew &&
          _vm.$permissions.isManageNetworkBillingAllowed()
            ? _c(
                "div",
                { staticClass: "bg-adori-light-gray br2 pa4 w-100" },
                [_c("YoutubeBilling")],
                1
              )
            : _vm._e(),
          _vm.selectedTab === "PLANS"
            ? _c(
                "div",
                { staticClass: "bg-adori-gray br2 w-100" },
                [_c("PlansV2")],
                1
              )
            : _vm._e(),
          _vm.selectedTab === "SUBSCRIPTION"
            ? _c(
                "div",
                { staticClass: "bg-adori-gray br2 w-100" },
                [_c("Subscription")],
                1
              )
            : _vm._e(),
          _vm.selectedTab === "USAGE"
            ? _c(
                "div",
                { staticClass: "bg-adori-gray br2 w-100" },
                [_c("BillingUsage")],
                1
              )
            : _vm._e(),
          _vm.selectedTab === "DEFAULT"
            ? _c("div", { staticClass: "bg-adori-light-gray br2 pa4" }, [
                _c("div", { staticClass: "white mb3 f4" }, [
                  _vm._v("Default Settings"),
                ]),
                _c(
                  "div",
                  { staticClass: "flex items-center light-gray pv2 f5" },
                  [
                    _vm._v("\n        Tag duration\n        "),
                    _c("BaseTooltip", {
                      staticClass: "ml1",
                      attrs: {
                        info: "Tag duration can be edited on individual tag level",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex items-center light-gray pv1 f6" },
                  [_vm._v("30 sec")]
                ),
                _c(
                  "div",
                  { staticClass: "flex items-center light-gray pv2 mt1 f5" },
                  [
                    _vm._v("\n        Tag transition\n        "),
                    _c("BaseTooltip", {
                      staticClass: "ml1",
                      attrs: { info: "Current transition on tags is Random" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex items-center light-gray pv1 f6" },
                  [_vm._v("Random")]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "flex flex-wrap fw3" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }