






























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import get from 'lodash.get'

@Component
export default class OverviewPodcastCard extends Vue {
  @Prop() rssFeed!: any
  @Prop(Boolean) isLargeCard!: boolean

  @Getter showUsers!: any
  @Getter networkType!: string
  @Getter allAutomations!: any
  @Getter isYoutube!: boolean

  @Action getShowUsers!: any
  @Action showMembers!: any
  @Action showAddShowMember!: any
  @Action closeModal!: any

  isLoading = false

  get rssShowUsers() {
    return this.showUsers(this.rssFeed.uid)
  }

  get podcast() {
    return this.rssFeed
  }
  get isAutomationAdded() {
    return get(this.podcast, 'automations.length') ? true : false
  }

  get isAutomationsEnabled() {
    return this.isAutomationAdded
      ? this.podcast.automations.filter((obj: any) => obj.type === 'youtube')[0].isEnabled
      : false
  }

  get publishStatusText() {
    return this.isAutomationAdded && this.isAutomationsEnabled
      ? 'Edit Automation'
      : this.isAutomationAdded && !this.isAutomationsEnabled
      ? 'Automation Disabled'
      : 'Add Automation'
  }

  get automationIcon() {
    return this.isAutomationAdded && this.isAutomationsEnabled
      ? 'auto_awesome'
      : this.isAutomationAdded && !this.isAutomationsEnabled
      ? 'auto_fix_off'
      : 'auto_mode'
  }

  get currentFeedAutomation() {
    return this.isAutomationAdded ? this.podcast.automations.filter((obj: any) => obj.type === 'youtube')[0] : null
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  handleShowMembers() {
    this.showMembers(this.rssFeed?.uid)
  }

  handleAddShowMember() {
    this.showAddShowMember(this.rssFeed?.uid)
  }

  handleShowClick() {
    this.$emit('onClick', this.rssFeed?.uid)
  }

  get isYoutubeData() {
    if (this.isYoutube && this.$permissions.isDeletePodcastShowAllowed(this.rssFeed?.uid)) {
      return true
    }
    return false
  }

  async handleAddAutomation() {
    if (
      this.publishStatusText === 'Add Automation' &&
      this.$env.windowOrigin() === this.$env.envConstProd() &&
      this.networkType !== 'YOUTUBE_ENTERPRISE'
    ) {
      const automationLen = get(this.allAutomations, 'youtube.length', 0)
      if (automationLen >= 1) {
        this.$store.dispatch('showConfirm', {
          title: 'Upgrade Network',
          description: 'Please upgrade your network in order to add automation to more than one podcast',
          yesButtonText: 'Contact us to upgrade your network',
          noButtonText: 'No, cancel',
          onConfirm: async () => {
            window.open('https://adorilabs.com/#contact', '_blank')
            this.closeModal()
          },
        })
        return
      }
    }
    this.$store.commit('setCurrentPodcast', this.podcast)
    this.$store.commit('setCreatedYoutubeFeed', { uid: this.rssFeed.uid })
    this.$router.push('/autopublish')
  }
}
