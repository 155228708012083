var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { key: "SHOW" }, [
    _vm.rssFeedLoading
      ? _c(
          "div",
          { staticClass: "flex flex-wrap vh-100" },
          _vm._l(new Array(20), function (_, index) {
            return _c("div", {
              key: index,
              staticClass:
                "bg-adori-light-gray bb bw1 b--adori-gray br2 ma2 skeleton custom-size",
            })
          }),
          0
        )
      : _vm.filteredRssFeed.length === 0 && _vm.podcastSearchValue === ""
      ? _c(
          "div",
          { staticStyle: { "padding-top": "4px" } },
          [
            _vm.isYoutube
              ? _c("HomeBanner", {
                  attrs: {
                    isLargeBanner: "",
                    bannerHeader: "Import a new podcast",
                    bannerSubHeader:
                      "Manage and deliver unlimited podcast shows and episodes. Publish your episodes with interactive tags to youtube, and unlock wider audience and opportunities!",
                    bannerImageUrl: _vm.bannerImageUrl,
                  },
                })
              : _c("OverviewBanner", {
                  attrs: {
                    isLargeBanner: "",
                    bannerHeader: _vm.bannerHeader,
                    bannerSubHeader: _vm.bannerSubHeader,
                    bannerImageUrl: _vm.bannerImageUrl,
                  },
                }),
            !_vm.isYoutube
              ? _c("OverviewInfoCard", { attrs: { isColumn: "" } })
              : _vm._e(),
          ],
          1
        )
      : _c("div", { staticClass: "flex justify-between flex-column" }, [
          _c("div", { staticClass: "flex justify-between" }, [
            _c(
              "div",
              { staticClass: "w-100 mr2" },
              [
                !_vm.selectedPodcast
                  ? _c("div", { staticClass: "flex relative bg-adori-gray" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.podcastSearchValue,
                            expression: "podcastSearchValue",
                          },
                        ],
                        ref: "_searchPodcasts",
                        staticClass:
                          "bg-adori-light-gray bw0 br2 pl3 pr5 pv3 f6 white",
                        staticStyle: {
                          "margin-bottom": "24px",
                          border: "1px solid #3f3f3f",
                          width: "58%",
                        },
                        attrs: { autofocus: "", placeholder: "Search shows" },
                        domProps: { value: _vm.podcastSearchValue },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.podcastSearchValue = $event.target.value
                          },
                        },
                      }),
                      _vm.podcastSearchValue.length === 0
                        ? _c(
                            "i",
                            {
                              staticClass: "material-icons silver absolute",
                              staticStyle: { top: "13px", right: "395px" },
                            },
                            [_vm._v("search")]
                          )
                        : _c(
                            "i",
                            {
                              staticClass:
                                "material-icons silver absolute pointer dim",
                              staticStyle: { top: "13px", right: "395px" },
                              on: { click: _vm.clearSearch },
                            },
                            [_vm._v("close")]
                          ),
                      _c(
                        "div",
                        {
                          staticClass: "f6 mt3 mb4 ml5 pointer",
                          class:
                            _vm.podcastSortOrder === "latestCreated"
                              ? "white bb bw1 b--white"
                              : "silver",
                          on: {
                            click: function ($event) {
                              return _vm.setSortOrder("latestCreated")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            Latest episode published\n          "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "f6 mt3 mb4 ml4 pointer",
                          class:
                            _vm.podcastSortOrder === "AZ"
                              ? "white bb bw1 b--white"
                              : "silver",
                          on: {
                            click: function ($event) {
                              return _vm.setSortOrder("AZ")
                            },
                          },
                        },
                        [_vm._v("\n            A-Z\n          ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "f6 mt3 mb4 ml4 pointer",
                          class:
                            _vm.podcastSortOrder === "ZA"
                              ? "white bb bw1 b--white"
                              : "silver",
                          on: {
                            click: function ($event) {
                              return _vm.setSortOrder("ZA")
                            },
                          },
                        },
                        [_vm._v("\n            Z-A\n          ")]
                      ),
                    ])
                  : _vm._e(),
                _vm.filteredRssFeed.length === 0
                  ? _c("div", { staticClass: "silver pt2 pb4" }, [
                      _vm._v("No Results!"),
                    ])
                  : !_vm.selectedPodcast
                  ? _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      _vm._l(_vm.filteredRssFeed, function (feed) {
                        return _c("div", { key: feed.uid }, [
                          _c(
                            "div",
                            [
                              _c("SettingsPodcastCard", {
                                staticClass: "pointer",
                                attrs: { rssFeed: feed, isLargeCard: false },
                                on: {
                                  onClick: function ($event) {
                                    return _vm.handleShowClick(feed)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.selectedPodcast
                  ? _c("ShowSetting", {
                      attrs: {
                        podcast: _vm.selectedPodcast,
                        isYoutube: _vm.isYoutube,
                        isNetworkOwner: _vm.isNetworkOwner,
                      },
                      on: {
                        clearSelectedPodcast: function ($event) {
                          _vm.selectedPodcast = null
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }