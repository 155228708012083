var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    true
      ? _c(
          "i",
          {
            staticClass: "material-icons light-gray dim mb2 pointer f2 mt2",
            on: {
              click: function () {
                return _vm.$emit("clearSelectedPodcast")
              },
            },
          },
          [_vm._v("keyboard_backspace")]
        )
      : _vm._e(),
    _vm.$permissions.isShowMangeAllowed(_vm.podcast.uid)
      ? _c("div", { staticClass: "pa4 bg-adori-light-gray br2" }, [
          _c("div", { staticClass: "flex items-center" }, [
            _c("img", {
              staticClass: "w4 h4",
              attrs: { src: _vm.podcast.imageUrl, alt: "" },
            }),
            _c("div", { staticClass: "light-gray f3 ml3" }, [
              _vm._v("\n        " + _vm._s(_vm.podcast.name) + "\n      "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "white mb2 mt2" },
            [
              _c("div", { staticClass: "white mb3 mt3 f4" }, [
                _vm._v("Show level youtube login"),
              ]),
              _c("YoutubeSignIn", { attrs: { showId: _vm.podcast.uid } }),
            ],
            1
          ),
          !["YOUTUBE", "YOUTUBE_METERED", "YOUTUBE_PRO"].includes(
            _vm.networkType
          ) || _vm.$env.envConstDev() === _vm.$env.windowOrigin()
            ? _c(
                "div",
                [
                  _vm._m(0),
                  _c("BaseButtonRed", {
                    staticClass: "mt2 mb4",
                    attrs: {
                      icon: "add",
                      text: "Add member",
                      onClick: _vm.handleAddShowMember,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("hr"),
          _c("div", { staticClass: "white mt3 mb4 f4" }, [
            _vm._v("\n      Members in your show"),
            _c("span", { staticClass: "silver f6 ml3" }, [
              _vm._v(
                "( " +
                  _vm._s((_vm.rssShowUsers && _vm.rssShowUsers.length) || 0) +
                  " )"
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex w-100 flex-wrap" },
            [
              _vm.isLoadingShowUsers
                ? _c(
                    "div",
                    { staticClass: "flex flex-wrap vh-30" },
                    _vm._l(new Array(5), function (_, index) {
                      return _c("div", {
                        key: index,
                        staticClass:
                          "bg-adori-gray bb bw1 b--adori-gray br2 w-30 ma2 skeleton custom-size",
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _vm._l(_vm.rssShowUsers || [], function (member) {
                return _c(
                  "div",
                  {
                    key: member.user.email,
                    staticClass: "flex flex-wrap justify-start w-30 br2",
                    staticStyle: { margin: "1rem" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 pa4 bg-adori-very-light-gray br2 word-wrap",
                      },
                      [
                        _c("p", { staticClass: "white fw5 f4 mb2" }, [
                          _vm._v(_vm._s(member.user.name)),
                        ]),
                        _c("p", { staticClass: "white f5 mb2" }, [
                          _vm._v(_vm._s(member.role.name)),
                        ]),
                        _c("p", { staticClass: "white mb4" }, [
                          _vm._v(_vm._s(member.user.email)),
                        ]),
                        _vm.userEmail !== member.user.email ||
                        _vm.isNetworkOwner
                          ? _c("BaseButtonBorder", {
                              attrs: {
                                icon: "remove_circle",
                                text: "Remove user",
                                params: member,
                                onClick: _vm.handleRemoveShowUser,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ])
      : _c("div", { staticClass: "pa4 bg-adori-light-gray br2" }, [
          _c("div", { staticClass: "silver f3 mb2" }, [
            _vm._v("\n      As a\n      "),
            _c("span", { staticClass: "fw5" }, [
              _vm._v(
                _vm._s(_vm.currentShowRole && _vm.currentShowRole.role.name)
              ),
            ]),
            _vm._v("\n      you are allowed to:\n    "),
          ]),
          _c(
            "div",
            { staticClass: "mb4" },
            _vm._l(
              (_vm.currentShowRole && _vm.currentShowRole.role.description) ||
                _vm.currentShowRole.role.description.ytDescription,
              function (des) {
                return _c("ul", { key: des, staticClass: "silver" }, [
                  _c("li", { staticClass: "f4 mb2" }, [_vm._v(_vm._s(des))]),
                ])
              }
            ),
            0
          ),
          _vm._m(1),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "white mb3 mt4 f4" }, [
      _vm._v("\n        Add member to your show"),
      _c("span", { staticClass: "silver f6 ml3" }, [
        _vm._v("Invite other users to your show"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "white f4" }, [
      _vm._v(
        "\n      You do not have the required permissions to change settings!\n      "
      ),
      _c("div", { staticClass: "mt2" }, [
        _vm._v("Please contact the network owner and request any changes."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }